<template>
  <Page
    @cta:click="onClick"
    :ctaClass="'danger'"
    :ctaLabel="'Back'"
    :title="'Edit user'"
    class="c-add-user"
  >
    <template #content>
      <el-tabs v-model="activeName">
        <el-tab-pane label="Personal Record" name="first">Personal Record</el-tab-pane>
        <el-tab-pane label="Physical Appearance" name="second">Physical Appearance</el-tab-pane>
        <el-tab-pane label="Education" name="third">Education</el-tab-pane>
        <el-tab-pane label="Family Details" name="fourth">Family Details</el-tab-pane>
      </el-tabs>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'

export default {
  components: {
    Page
  },

  data () {
    return {
      activeName: 'first'
    }
  },

  methods: {
    onClick () {
      this.$router.push({ name: 'users' })
    }
  },

  name: 'UserEdit'
}
</script>
